<template>
  <div>
    <v-card width="80%" style="margin-left: 10%; margin-top: 20px;" tile outlined elevation="5">
      Hallo
    </v-card>
    <v-card width="80%" tile outlined elevation="5" height="310" style="margin-top: 25px; margin-left: 10%; margin-right: 10%; margin-bottom: 50px">
      <div style="display: flex">
        <img src="../assets/logo.jpg">
        <b><div style="font-size: 25px; position: absolute; top: 25px; left: 330px">Mitterstätter GmbH</div></b>
      </div>
      <div style="display: flex">
        <div style="position: absolute; top: 80px; left: 20px">
          <p style="margin-bottom: 0px; margin-top: 10px">Max Valier Straße 7</p>
          <p style="margin-bottom: 0px;">39040 Auer</p>
          <p>Provinz Bozen Südtirol</p>
          <p style="margin-bottom: 0px;">Telefon: +39 0471 810 235</p>
          <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
          <div style="font-size: 13px">MwSt.-Nr. 01162190217</div>
        </div>
        <div style="position: absolute; top: 80px; left: 400px">
          <p style="margin-bottom: 0px;"><b>Öffnungszeiten</b></p>
          <p>Montag-Freitag</p>
          <p style="margin-bottom: 0px;">08:00 Uhr - 12:00 Uhr</p>
          <p>14:00 Uhr - 18:00 Uhr</p>
          <p style="margin-bottom: 0px;">Telefondienst</p>
          <p>7:30 Uhr - 19:30 Uhr</p>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" width="400" height="200" style="border:0; position: absolute; top: 70px; right: 30px" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </v-card>
    <div style="display: flex;">
      <v-btn to="/deu" style="margin-left: 10%" elevation="5" >Zur Hauptseite</v-btn>
      <v-spacer></v-spacer>
      <div style="font-size: 13px; text-align: right; margin-right: 10%; margin-bottom: 25px">
        <router-link to="/impressum" class="impressum">Impressum</router-link> | <router-link to="/datenschutz" class="impressum">Datenschutz</router-link>
         <!-- | <router-link to="/cookie" class="impressum">Cookie</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
};
</script>

<style scoped>
.impressum{
  color: black;
  text-decoration: none;
}
</style>
<template>
  <div>
    <v-img v-bind:src="src[index]" :key="index" class="src"></v-img>
    <v-card width="90%" style="margin-left: 5%; margin-top: 1.5%;" tile outlined elevation="5">
      <h1 style="margin-top: 5%; margin-left: 15%; margin-bottom: 0%">Das sind wir</h1>
      <v-card hover width="60%" elevation="5" shaped outlined style="margin-left: 20%; margin-right: 20%; margin-top: 2%">
        <p class="container">
          Mitterstätter Brennstoffe
        </p>
        <p class="container">
          Ihr Heizölhändler und Treibstofflieferant (Uma-Treibstoff + Diesel) in Südtirol und Trentino.
        </p>
        <p class="container">
          Wir sind ein Familienunternehmen welches im Jahre 1972 von unseren Eltern Mitterstätter Ulrich und Andergassen
          Rosa gegründet wurde und mittlerweile in 2. Generation von Mitterstätter Dieter und Mitterstätter Werner geführt wird. Auch die 3. Generation steht schon in den Startlöchern.
        </p>
        <p class="container">
          Am Anfang wurde das Heizöl zugestellt und der Uma-Treibstoff konnte im Depot mittels Fässer oder Kanister abgeholt werden. Als eine
          der ersten Firmen in Südtirol haben wir in den 1980er Jahren damit begonnen, den Uma-Treibstoff zuzustellen und haben damit Pionierarbeit geleistet.
        </p>
        <p class="container">
          Mittlerweile wird seit einigen Jahren neben dem Heizöl, der Uma-Treibstoff und der Dieseltreibstoff ausschließlich zugestellt. Wobei
          die Zustellungszonen laufend erweitert wurden.
        </p>
        <p class="container">
          Zonen Südtirol und Trentino:<br>
          Unterland, Überetsch, Bozen, Bozen Land, Eggental, Ultental, Burggrafenamt, Meran, Meran Umgebung, Passeiertal, Vinschgau, Martell, Schnals, Prad und Umgebung, Graun im Vinschgau
          und Umgebung, Tschöggelberg, Sarntal, Ritten, Eisacktal, Wipptal, Ridnauntal, Pustertal, Gröden, Schlerngebiet, Fassatal, Fleimstal, Nonstal, Etschtal, Valsugana.
        </p>
      </v-card>
      <h1 style="margin-top: 3%; margin-left: 15%; margin-bottom: 0%">Unsere Produkte</h1>
      <div style="margin-left: 20%; margin-right: 20%">
      <v-row no-gutters>
        <v-col>
          <v-btn elevation="5" @click="changeCards(0)" width="100%">
            Heizen
          </v-btn>
        </v-col>
        <v-col>
          <v-btn elevation="5" @click="changeCards(1)" width="100%">
            Landwirtschaft
          </v-btn>
        </v-col>
        <v-col>
          <v-btn elevation="5" @click="changeCards(2)" width="100%">
            Dieseltreibstoff
          </v-btn>
        </v-col>
      </v-row>
      </div>
      <v-btn fab class="button4" color="white" @click="changeCard">
        <v-icon class="icon">mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn fab class="button3" color="white" @click="changeCard2">
        <v-icon class="icon">mdi-arrow-left</v-icon>
      </v-btn>
      <v-card hover width=60% elevation="5" shaped outlined style="margin-left: 20%; margin-right: 20%; margin-top: 3%; margin-bottom: 4%">
        <v-img v-bind:src="image[i]" :key="i" height="250px" width="100%"></v-img>
        <v-card-title class="container">
          {{title[i]}}
        </v-card-title>
        <v-card-text class="container">
          <b>{{t[i]}}</b><br>
          <span v-if="t2[i]"><b>{{t2[i]}}</b></span><br>
          <div v-if="t3[i]"><b>{{t3[i]}}</b></div><br>
          {{text[i]}}<br>
          {{text2[i]}}<a href="mailto:info@mittercom.it">info@mittercom.it</a>
        </v-card-text>        
      </v-card>
    </v-card>
    <v-card width="90%" tile outlined elevation="5" style="margin-top: 25px; margin-left: 5%; margin-right: 5%; margin-bottom: 25px; height: auto">
      <v-row no-gutters>
        <v-col cols="12"
          sm="4">
          <img src="../assets/logo.jpg">
        </v-col>
        <v-col>
          <b><div style="font-size: 170%; margin-left: 2%">Mitterstätter GmbH</div></b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
        <div style="margin-top: 1%; margin-left: 2%; margin-bottom: 1%;">
          <p style="margin-bottom: 0%;">Max Valier Straße 7</p>
          <p style="margin-bottom: 0%;">39040 Auer</p>
          <p>Provinz Bozen Südtirol</p>
          <p style="margin-bottom: 0%;">Telefon: +39 0471 810 235</p>
          <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
          <div style="font-size: 80%">MwSt.-Nr. 01162190217</div>
        </div>
        </v-col>
        <v-col>
        <div style="margin-left: 2%;">
          <p style="margin-bottom: 0%;"><b>Öffnungszeiten</b></p>
          <p>Montag-Freitag</p>
          <p style="margin-bottom: 0%;">08:00 Uhr - 12:00 Uhr</p>
          <p>14:00 Uhr - 18:00 Uhr</p>
          <p style="margin-bottom: 0%;">Telefondienst</p>
          <p>7:30 Uhr - 19:30 Uhr</p>
        </div>
        </v-col>
        <v-col>
        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" height="75%" style="border:0; margin-left: 2%;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
      </v-row>
    </v-card>
    <div style="margin-left: 5%; display: flex; margin-right: 5%">
    <div style="font-size: 80%">
    <v-icon>mdi-copyright</v-icon>
    Copyright 2021 Mitterstaetter GmbH. All Rights reserved. | MwSt. IT01162190217
    </div>
    <v-spacer></v-spacer>
    <div style="font-size: 80%; margin-bottom: 2%">
      <router-link to="/impressum" class="impressum">Impressum</router-link> | <router-link to="/datenschutz" class="impressum">Datenschutz</router-link>
       <!-- | <router-link to="/cookie" class="impressum">Cookie</router-link> -->
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hauptseite",

  data() {
    return {
      src: [ require(`../assets/LKW_seitlich.jpg`),require(`../assets/LKW_hinten.jpg`)],
      index: 0,
      mainImageSrc: null,
      title: ["Heizen", "Landwirtschaft", "Diesel"],
      text: ["Für Bestellungen oder jegliche Information rufen Sie uns an 0471 810235.", "Für Bestellungen oder jegliche Information rufen Sie uns an 0471 810235.", "Für Bestellungen oder jegliche Information rufen Sie uns an 0471 810235."],
      text2: ["Falls die Leitung besetzt ist können Sie uns gerne eine Mail schicken und wir melden uns so bald wie möglich bei Ihnen. E-Mail ","Falls die Leitung besetzt ist können Sie uns gerne eine Mail schicken und wir melden uns so bald wie möglich bei Ihnen. E-Mail ","Falls die Leitung besetzt ist können Sie uns gerne eine Mail schicken und wir melden uns so bald wie möglich bei Ihnen. E-Mail "],
      image: [ require(`../assets/heizöl.jpg`), require(`../assets/agricolo.jpg`), require(`../assets/diesel.jpg`)],
      i: 0,
      t: ["Leichtöl für Heizzwecke", "Landwirtschaftliches Gasöl - Uma Treibstoff","Dieseltreibstoff"],
      t2: ["","Landwirtschaftliches Benzin - Uma Benzin",""],
      t3: ["","Dieseltreibstoff (bei Bedarf mittels Eigenerklärung mit 10% MwSt)",""]
    }
  },
  created() {
    let self = this;
    setInterval(function(){
        if (self.index < self.src.length-1) {
          self.index++;
        } else {
          self.index=0;
        }
    }, 15000);
  },
  methods: {
    changeCard: function() {
      if (this.i < this.image.length-1) {
        this.i++
      } else {
        this.i=0;
      }
    },
    changeCard2: function() {
      if (this.i != 0) {
        this.i--
      } else {
        this.i=this.image.length-1;
      }
    },
    changeCards(a) {
      this.i=a;
    }
  },
};
</script>

<style scoped>
.container {
  padding: 10px;
  margin: auto;
  max-width: 800px;
}
.src {
  width: 100%;
}
.impressum{
  color: black;
  text-decoration: none;
}

.button3 {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 2%;
  top: 78%;
}

.button4 {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 2%;
  top: 78%;
}
.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    width: 3%;
    height: 3%;
}
</style>
<template>
  <div>
    <v-card width="90%" style="margin-left: 5%; margin-top: 20px;" tile outlined elevation="5">
      <p style="margin-top: 25px; margin-left: 20px; margin-right: 10px;">
        Titolare del trattamento di dati personali è la Mitterstätter s.r.l., con sede in 39040 Ora, Provincia Bolzano Alto Adige, Via Max Valier n. 7. Lei ha il diritto di sapere quali dati personali sono stati
        memorizzati e dopo la scadenza legale per l'obbligo della tenuta dei registri contabili di chiedere la cancellazione dei stessi. Per ogni richiesta mandare una e-mail a info@mittercom.it o telefonare al 0471 810235.
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Da parte nostra vengono richieste dati personali per la corretta gestione degli vostri ordini:
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Emissione bolle di accompagnamento<br>
        Emissione fatture elettroniche<br>
        Emissione di eventuali dichiarazioni sostitutive per le riduzioni d'imposte<br>
        Tenuta regolare di tutti registri contabili
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Da parte nostra dati personali vengono dati solo a amministrazioni pubbliche, commercialisti o avvocati per tenere la contabilità a norma di legge.
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Al momento dell'ordine da parte Vostra ci date l'assenso del trattamento dei dati personali come sopra citato. I Vostri dati personali da noi non vengono elaborati per motivi 
        di statistica ne vengono messi a disposizione a terzi per motivi di pubblicità.
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Mitterstaetter s.r.l.
      </p>
    </v-card>
    <v-card width="90%" tile outlined elevation="5" style="margin-top: 25px; margin-left: 5%; margin-right: 5%; margin-bottom: 25px; height:auto;">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <img src="../assets/logo.jpg">
        </v-col>
        <v-col>
          <b><div style="font-size: 170%; margin-left: 2%;">Mitterstaetter s.r.l.</div></b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <div style="margin-top: 1%; margin-left: 2%; margin-bottom: 1%;">
            <p style="margin-bottom: 0%;">Via Max Valier 7</p>
            <p style="margin-bottom: 0%;">39040 Ora</p>
            <p>Provincia Bolzano Alto Adige</p>
            <p style="margin-bottom: 0%;">Telefono: +39 0471 810 235</p>
            <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
            <div style="font-size: 80%">P. IVA 01162190217</div>
          </div>
        </v-col>
        <v-col>
          <div style="margin-left: 2%;">
            <p style="margin-bottom: 0%;"><b>Orari di apertura</b></p>
            <p>Lunedí-Venerdí</p>
            <p style="margin-bottom: 0%;">08:00 - 12:00</p>
            <p>14:00 - 18:00</p>
            <p style="margin-bottom: 0%;">Servizio Telefono</p>
            <p>07:30 - 19:30</p>
          </div>
        </v-col>
        <v-col >
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" height="75%" style="border:0; margin-left: 2%;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
      </v-row>
    </v-card>
    <div style="display: flex;">
      <v-btn to="/ita" style="margin-left: 5%" elevation="5" >Ritorna al sito</v-btn>
      <v-spacer></v-spacer>
      <div style="font-size: 80%; text-align: right; margin-right: 5%; margin-bottom: 25px">
        <router-link to="/impressum2" class="impressum">Impressum</router-link> | <router-link to="/datenschutz2" class="impressum">Privacy</router-link>
         <!-- | <router-link to="/cookie2" class="impressum">Cookie</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Datenschutz2",
};
</script>

<style scoped>
.impressum{
  color: black;
  text-decoration: none;
}
</style>
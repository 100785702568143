<template>
  <div>
    <v-card width="90%" style="margin-left: 5%; margin-top: 20px;" tile outlined elevation="5">
      <h1 style="margin-top: 10px; margin-left: 10px">Mitterstaetter s.r.l.</h1>
      <p style="margin-bottom: 0px; margin-top: 10px; margin-left: 10px">Via Max Valier 7</p>
      <p style="margin-bottom: 0px; margin-left: 10px">39040 Ora</p>
      <p style="margin-left: 10px">Provincia Bolzano Alto Adige</p>
      <p style="margin-bottom: 0px; margin-left: 10px">Telefono: +39 0471 810 235</p>
      <p style="margin-bottom: 0px; margin-left: 10px">Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
      <p style="margin-left: 10px">Pec: mitterstaettersrl@pec.rolmail.it</p>
      <div style="margin-left: 10px; margin-bottom: 10px">P. IVA 01162190217 C.F.</div>
    </v-card>
    <v-card width="90%" tile outlined elevation="5" style="margin-top: 25px; margin-left: 5%; margin-right: 5%; margin-bottom: 25px; height:auto;">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <img src="../assets/logo.jpg">
        </v-col>
        <v-col>
          <b><div style="font-size: 170%; margin-left: 2%;">Mitterstaetter s.r.l.</div></b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <div style="margin-top: 1%; margin-left: 2%; margin-bottom: 1%;">
            <p style="margin-bottom: 0%;">Via Max Valier 7</p>
            <p style="margin-bottom: 0%;">39040 Ora</p>
            <p>Provincia Bolzano Alto Adige</p>
            <p style="margin-bottom: 0%;">Telefono: +39 0471 810 235</p>
            <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
            <div style="font-size: 80%">P. IVA 01162190217</div>
          </div>
        </v-col>
        <v-col>
          <div style="margin-left: 2%;">
            <p style="margin-bottom: 0%;"><b>Orari di apertura</b></p>
            <p>Lunedí-Venerdí</p>
            <p style="margin-bottom: 0%;">08:00 - 12:00</p>
            <p>14:00 - 18:00</p>
            <p style="margin-bottom: 0%;">Servizio Telefono</p>
            <p>07:30 - 19:30</p>
          </div>
        </v-col>
        <v-col >
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" height="75%" style="border:0; margin-left: 2%;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
      </v-row>
    </v-card>
    <div style="display: flex;">
      <v-btn to="/ita" style="margin-left: 5%" elevation="5" >Ritorna al sito</v-btn>
      <v-spacer></v-spacer>
      <div style="font-size: 80%; text-align: right; margin-right: 5%; margin-bottom: 25px">
        <router-link to="/impressum2" class="impressum">Impressum</router-link> | <router-link to="/datenschutz2" class="impressum">Privacy</router-link>
         <!-- | <router-link to="/cookie2" class="impressum">Cookie</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Impressum2",
};
</script>

<style scoped>
.impressum{
  color: black;
  text-decoration: none;
}
</style>
<template>
  <v-app style="background-color: orange">
    <v-app-bar app style="height: 65px" color="white" dark>
      <div style="display: flex">
        <a href="/">
          <v-img
            style="position: absolute; top: 5px; left: 0px"
            contain
            src="./assets/logo.jpg"
            width="140px"
          />
        </a>
      <v-spacer></v-spacer>

      <v-btn to="/deu" color="white" style=" width: 120px; height: 45px; color: black; position: absolute; top: 0px; right: 130px">
        <img class="flaggen" src="./assets/deutsch.png">
        Deutsch
      </v-btn>
      <v-btn to="/ita" color="white" style=" width: 120px;height: 45px; color: black; position: absolute; top: 0px; right: 10px">
        <img class="flaggen" src="./assets/italien.png">
        Italiano
      </v-btn>
      </div>
      <hr width=100% color="orange" style="position: absolute; top:45px; left: 0px; height: 20px; background-color: orange; border-radius: 0px">
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.flaggen {
    margin-right: 5px;
    height: 20px;
}
@media (min-width: 600px) {

}
</style>
<template>
  <div>
    <v-card width="90%" style="margin-left: 5%; margin-top: 20px;" tile outlined elevation="5">
      <p style="margin-top: 25px; margin-left: 20px; margin-right: 10px;">
        Inhaber Ihrer persönlichen Daten ist die Mitterstätter GmbH, mit Sitz in 39040 Auer, Provinz Bozen Südtirol, Max Valier Str. 7. Sie haben das Recht zu erfahren welche Daten gespeichert wurden und diese auch,
        nach Ablauf der gesetzlichen Frist zur Aufbewahrung der Buchhaltung, zur Gänze löschen zu lassen. Für jegliche Anfrage schicken Sie eine E-Mail an info@mittercom.it oder telefonieren Sie an die 0471 810235.
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Es werden unsererseits nur Daten verlangt welche für die korrekte Abwicklung Ihres Auftrages nötig sind:
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Ausstellung der Lieferscheine<br>
        Ausstellung der elektronischen Rechnungen<br>
        Ausstellung eventueller Ersatzerklärungen für die Steuerreduzierungen<br>
        Führung aller Register welche für die Buchhaltung notwendig sind
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Es werden unsererseits Daten nur an öffentlichen Behörden, Wirtschaftsberater oder Rechtsanwälte weitergegeben um den gesetzlichen Pflichten zur Führung der Buchhaltung nachzukommen
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Bei Auftragserteilung sind Sie damit einverstanden, dass Ihre Daten wie oben angeführt verwendet werden. Des Weiteren machen wir Sie darauf aufmerksam, dass Ihre Daten von uns nicht zu statistischen Zwecken
        verarbeitet werden und dass wir Ihre Daten an keine dritten Firmen für Werbezwecke weitergeben.
      </p>
      <p style="margin-left: 20px; margin-right: 10px; margin-bottom: 25px">
        Mitterstätter GmbH
      </p>
    </v-card>
<v-card width="90%" tile outlined elevation="5" style="margin-top: 25px; margin-left: 5%; margin-right: 5%; margin-bottom: 25px; height: auto">
      <v-row no-gutters>
        <v-col cols="12"
          sm="4">
          <img src="../assets/logo.jpg">
        </v-col>
        <v-col>
          <b><div style="font-size: 170%; margin-left: 2%">Mitterstätter GmbH</div></b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4">
        <div style="margin-top: 1%; margin-left: 2%; margin-bottom: 1%;">
          <p style="margin-bottom: 0%;">Max Valier Straße 7</p>
          <p style="margin-bottom: 0%;">39040 Auer</p>
          <p>Provinz Bozen Südtirol</p>
          <p style="margin-bottom: 0%;">Telefon: +39 0471 810 235</p>
          <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
          <div style="font-size: 80%">MwSt.-Nr. 01162190217</div>
        </div>
        </v-col>
        <v-col>
        <div style="margin-left: 2%;">
          <p style="margin-bottom: 0%;"><b>Öffnungszeiten</b></p>
          <p>Montag-Freitag</p>
          <p style="margin-bottom: 0%;">08:00 Uhr - 12:00 Uhr</p>
          <p>14:00 Uhr - 18:00 Uhr</p>
          <p style="margin-bottom: 0%;">Telefondienst</p>
          <p>7:30 Uhr - 19:30 Uhr</p>
        </div>
        </v-col>
        <v-col>
        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" height="75%" style="border:0; margin-left: 2%;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
      </v-row>
    </v-card>
    <div style="display: flex;">
      <v-btn to="/deu" style="margin-left: 5%" elevation="5" >Zur Hauptseite</v-btn>
      <v-spacer></v-spacer>
      <div style="font-size: 80%; text-align: right; margin-right: 5%; margin-bottom: 25px">
        <router-link to="/impressum" class="impressum">Impressum</router-link> | <router-link to="/datenschutz" class="impressum">Datenschutz</router-link>
        <!-- | <router-link to="/cookie" class="impressum">Cookie</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Datenschutz",
};
</script>

<style scoped>
.impressum{
  color: black;
  text-decoration: none;
}
</style>
<template>
  <div>
    <v-img v-bind:src="src[index]" :key="index" class="src"></v-img>
    <v-card width="90%" style="margin-left: 5%; margin-top: 1.5%;" tile outlined elevation="5">
      <h1 style="margin-top: 5%; margin-left: 15%; margin-bottom: 0%">Chi siamo</h1>
      <v-card hover width="60%" elevation="5" shaped outlined style="margin-left: 20%; margin-right: 20%; margin-top: 2%">
        <p class="container">
          Mitterstaetter Combustibili 
        </p>
        <p class="container">
          Il Vostro fornitore di gasolio riscaldamento, gasolio agricolo e gasolio autotrazione nell' Alto Adige e Trentino. 
        </p>
        <p class="container">
          Siamo un' azienda familiare fondata nel 1972 dai nostri genitori Mitterstätter Ulrich e Andergassen Rosa, gestita in
          2. generazione di Mitterstätter Dieter e Mitterstätter Werner. La 3. generazione è già nella fase di inizio.
        </p>
        <p class="container">
          Nei primi anni consegnavamo gasolio riscaldamento e il gasolio agricolo veniva venduto tramite fusti o taniche franco deposito. Negli anni 
          '80 eravamo una delle prime aziende ad iniziare anche la consegna a domicilio del gasolio agricolo facendo lavoro pionieristico.
        </p>
        <p class="container">
          Da un paio di anni, come il gasolio riscaldamento, anche il gasolio agricolo e il gasolio autotrazione vengono esclusivamente consegnati a domicilio.
        </p>
        <p class="container">
          Zone Alto Adige e Trentino:<br>
          Bassa Atesina, Oltradige, Bolzano e dintorni, Val d'Ega, Val d'Ultimo, Burgraviato, Merano e dintorni, Val Passiria, Val Venosta, Martello, Senales, Prato allo Stelvio e dintorni, Curon Venosta
          e dintorni, Monzoccolo, Sarentino, Renon, Valle Isarco, Alta Valle Isarco, Val Ridanna, Val Pusteria, Val Gardena, Altopiano dello Sciliar, Val di Fassa, Val di Fiemme, Val di Non, Val d'Adige, Valsugana.
        </p>
      </v-card>
      <h1 style="margin-top: 3%; margin-left: 15%; margin-bottom: 0%">I nostri Prodotti</h1>
      <div style="margin-left: 20%; margin-right: 20%">
      <v-row no-gutters>
        <v-col>
          <v-btn elevation="5" @click="changeCards(0)" width="100%">
            Riscaldamento
          </v-btn>
        </v-col>
        <v-col>
          <v-btn elevation="5" @click="changeCards(1)" width="100%">
            Agricoltura
          </v-btn>
        </v-col>
        <v-col>
          <v-btn elevation="5" @click="changeCards(2)" width="100%">
            Autotrazione
          </v-btn>
        </v-col>
      </v-row>
      </div>
      <v-btn fab class="button4" color="white" @click="changeCard">
        <v-icon class="icon">mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn fab class="button3" color="white" @click="changeCard2">
        <v-icon class="icon">mdi-arrow-left</v-icon>
      </v-btn>
      <v-card hover width=60% elevation="5" shaped outlined style="margin-left: 20%; margin-right: 20%; margin-top: 3%; margin-bottom: 4%">
        <v-img v-bind:src="image[i]" :key="i" height="250px" width="100%"></v-img>
        <v-card-title class="container">
          {{title[i]}}
        </v-card-title>
        <v-card-text class="container">
          <b>{{t[i]}}</b><br>
          <span v-if="t2[i]"><b>{{t2[i]}}</b></span><br>
          <div v-if="t3[i]"><b>{{t3[i]}}</b></div><br>
          {{text[i]}}<br>
          {{text2[i]}}<a href="mailto:info@mittercom.it">info@mittercom.it</a>
        </v-card-text>        
      </v-card>
    </v-card>
    <v-card width="90%" tile outlined elevation="5" style="margin-top: 25px; margin-left: 5%; margin-right: 5%; margin-bottom: 25px; height:auto;">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <img src="../assets/logo.jpg">
        </v-col>
        <v-col>
          <b><div style="font-size: 170%; margin-left: 2%;">Mitterstaetter s.r.l.</div></b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <div style="margin-top: 1%; margin-left: 2%; margin-bottom: 1%;">
            <p style="margin-bottom: 0%;">Via Max Valier 7</p>
            <p style="margin-bottom: 0%;">39040 Ora</p>
            <p>Provincia Bolzano Alto Adige</p>
            <p style="margin-bottom: 0%;">Telefono: +39 0471 810 235</p>
            <p>Email: <a href="mailto:info@mittercom.it">info@mittercom.it</a></p>
            <div style="font-size: 80%">P. IVA 01162190217</div>
          </div>
        </v-col>
        <v-col>
          <div style="margin-left: 2%;">
            <p style="margin-bottom: 0%;"><b>Orari di apertura</b></p>
            <p>Lunedí-Venerdí</p>
            <p style="margin-bottom: 0%;">08:00 - 12:00</p>
            <p>14:00 - 18:00</p>
            <p style="margin-bottom: 0%;">Servizio Telefono</p>
            <p>07:30 - 19:30</p>
          </div>
        </v-col>
        <v-col >
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13719.25090725816!2d11.306026991512228!3d46.352027211188464!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sit!4v1626686007461!5m2!1sde!2sit" height="75%" style="border:0; margin-left: 2%;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
      </v-row>
    </v-card>
    <div style="margin-left: 5%; display: flex; ; margin-right: 5%">
    <div style="font-size: 80%">
    <v-icon>mdi-copyright</v-icon>
    Copyright 2021 Mitterstaetter s.r.l. All Rights reserved. | P. IVA IT01162190217
    </div>
    <v-spacer></v-spacer>
    <div style="font-size: 80%; margin-bottom: 2%">
      <router-link to="/impressum2" class="impressum">Impressum</router-link> | <router-link to="/datenschutz2" class="impressum">Privacy</router-link>
      <!-- | <router-link to="/cookie2" class="impressum">Cookie</router-link>-->
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hauptseite",

  data() {
    return {
      src: [ require(`../assets/LKW_seitlich.jpg`),require(`../assets/LKW_hinten.jpg`)],
      index: 0,
      mainImageSrc: null,
      title: ["Riscaldamento", "Agricoltura", "Autotrazione"],
      text: ["Per ordini o ogni informazione telefonare al n. 0471 810235.", "Per ordini o ogni informazione telefonare al n. 0471 810235.", "Per ordini o ogni informazione telefonare al n. 0471 810235."],
      text2: ["Se trovate la linea occupata ci potete mandare una mail e vi contatteremo al più presto possibile. E-Mail ", "Se trovate la linea occupata ci potete mandare una mail e vi contatteremo al più presto possibile. E-Mail ", "Se trovate la linea occupata ci potete mandare una mail e vi contatteremo al più presto possibile. E-Mail "],
      image: [ require(`../assets/LKW_hinten.jpg`), require(`../assets/agricolo.jpg`), require(`../assets/diesel.jpg`)],
      i: 0,
      t: ["Gasolio riscaldamento", "Gasolio agricolo", "Gasolio autotrazione - Diesel"],
      t2: ["", "Benzina agricola", ""],
      t3: ["", "Autotrazione - Diesel (con dichiarazione sostitutiva per agricoltura IVA 10%)", ""],
    }
  },
  created() {
    let self = this;
    setInterval(function(){ 
        if (self.index < self.src.length-1) {
          self.index++;
        } else {
          self.index=0;
        }
    }, 15000);
  },
  methods: {
    changeCard: function() {
      if (this.i < this.image.length-1) {
        this.i++
      } else {
        this.i=0;
      }
    },
    changeCard2: function() {
      if (this.i != 0) {
        this.i--
      } else {
        this.i=this.image.length-1;
      }
    },
    changeCards(a) {
      this.i=a;
    }
  },
};
</script>

<style scoped>
.container {
  padding: 10px;
  margin: auto;
  max-width: 800px;
}
.src {
  width: 100%;
}
.button3 {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 2%;
  top: 78%;
}

.button4 {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 2%;
  top: 78%;
}

.impressum{
  color: black;
  text-decoration: none;
}
.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    width: 3%;
    height: 3%;
}
</style>
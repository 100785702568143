import Vue from "vue";
import VueRouter from "vue-router";
import Hauptseite from "../views/Hauptseite.vue";
import Hauptseite2 from "../views/Hauptseite2.vue";
import Impressum from "../views/Impressum.vue";
import Impressum2 from "../views/Impressum2.vue";
import Datenschutz from "../views/Datenschutz.vue";
import Datenschutz2 from "../views/Datenschutz2.vue";
import Cookie from "../views/Cookie.vue";
import Cookie2 from "../views/Cookie2.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Hauptseite",
    component: Hauptseite,
  },
  {
    path: "/deu",
    name: "Hauptseite",
    component: Hauptseite,
  },
  {
    path: "/ita",
    name: "Hauptseite2",
    component: Hauptseite2,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/impressum2",
    name: "Impressum2",
    component: Impressum2,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
  },
  {
    path: "/datenschutz2",
    name: "Datenschutz2",
    component: Datenschutz2,
  },
  {
    path: "/cookie",
    name: "Cookie",
    component: Cookie,
  },
  {
    path: "/cookie2",
    name: "Cookie2",
    component: Cookie2,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
